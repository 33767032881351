import React from 'react'
import { Link } from 'react-router-dom';

import shree_logo from '../../assets/images/client/shree-logo.png';
import skype from '../../assets/images/client/skype.png';
import snapchat from '../../assets/images/client/snapchat.png';
import spotify from '../../assets/images/client/spotify.png';
import telegram from '../../assets/images/client/telegram.png';
import whatsapp from '../../assets/images/client/whatsapp.png';
import android from '../../assets/images/client/android.png';
import facebook_logo_2019 from '../../assets/images/client/facebook-logo-2019.png';
import linkedin from '../../assets/images/client/linkedin.png';
import google_logo from '../../assets/images/client/google-logo.png';
import Navbar from '../../component/Navbar/navbar'
import CookieModal from '../../component/cookieModal';
import JobFooter from '../../component/Footer/jobFooter';

import CountUp from 'react-countup';
import Select from 'react-select'

import {FaArrowRight, AiOutlineHome,AiOutlineDollar, LuSearch, GoClock} from '../../assets/icons/icons'

 
export default function IndexJob() {
    let settings = {
        container: '.tiny-five-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 5
            },
    
            992: {
                items: 4
            },
    
            767: {
                items: 3
            },
    
            425: {
                items: 1
            },
        },
    }
    let country = [
        { value: '1', label: 'Afghanistan' },
        { value: '2', label: 'Azerbaijan' },
        { value: '3', label: 'Bahamas' },
        { value: '4', label: 'Bahrain' },
        { value: '5', label: 'Canada' },
        { value: '5', label: 'Denmark' },
        { value: '5', label: 'Djibouti' },
        { value: '5', label: 'Eritrea' },
        { value: '5', label: 'Estonia' },
        { value: '5', label: 'Gambia' },
    ]
    let time = [
        { value: '1', label: 'Part Time' },
        { value: '2', label: 'Freelancer' },
        { value: '3', label: 'Remote Work' },
        { value: '4', label: 'Office Work' },
    ]
   
    return (
        <>
            <Navbar navClass="nav-sticky" />
            <section className="md:h-screen py-36 h-auto relative flex items-center background-effect overflow-hidden bg-[url('../../assets/images/job/job.jpg')] bg-cover">
                <div className="container-fluid">
                    <div className="absolute inset-0 z-0 bg-[url('../../assets/images/job/curve-shape.png')] dark:bg-[url('../../assets/images/job/curve-shape-dark.png')] bg-cover"></div>
                </div>

                <div className="container relative z-1">
                    <div className="grid grid-cols-1 mt-10">
                        <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">Build Your Future <br/>with Us</h4>
                        <p className="text-slate-400 text-lg max-w-xl">Join us in driving change and innovation. Explore career paths that inspire growth and success.</p>

                        <div className="grid lg:grid-cols-12 grid-cols-1" id="reserve-form">
                            <div className="lg:col-span-10 mt-8">
                                
                            </div>
                        </div>

                        {/* <div className="mt-6">
                            <span className="text-slate-400"><span className="text-dark">Popular Searches :</span> Designer Developer Web IOS PHP Senior Engineer</span>
                        </div> */}
                    </div>
                </div>

                <div className="absolute inset-0 bg-indigo-600/5"></div>
                <ul className="circles p-0 mb-0">
                    <li className="brand-img"><img src={shree_logo} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={skype} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={snapchat} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={spotify} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={telegram} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={whatsapp} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={android} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={facebook_logo_2019} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={linkedin} className="size-9" alt="" /></li>
                    <li className="brand-img"><img src={google_logo} className="size-9" alt="" /></li>
                </ul>
            </section>


            <div className="relative">
                <div className="absolute block w-full h-auto bottom-[25px] z-1 start-0">
                    <Link ><i className="mdi mdi-arrow-down absolute top-0 start-0 end-0 text-center inline-flex items-center justify-center rounded-full bg-white dark:bg-slate-900 size-12 mx-auto shadow-md dark:shadow-gray-800"></i></Link>
                </div>

                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div class='container mt-24'>
                <div className="bg-white dark:bg-slate-900 border-0 shadow rounded p-3">
                    <form action="#">
                        <div className="registration-form text-dark text-start">
                                <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 lg:gap-0 gap-6">
                                <div>
                                    <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                        <LuSearch  className="icons  absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/>
                                        <input name="name" type="text" id="job-keyword" className="form-input lg:rounded-t-sm lg:rounded-e-none lg:rounded-b-none lg:rounded-s-sm lg:outline-0 w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-0" placeholder="Search your Keywords" />
                                    </div>
                                </div>

                                <div>                                                        
                                    <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                        <AiOutlineHome className="icons  absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/>
                                        <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={country} />

                                        </div>
                                    </div>

                                <div>                                                       
                                    <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                        <AiOutlineDollar className="icons  absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"/>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={time} />

                                    </div>
                                    </div>

                                <div>
                                    <div type="submit" id="search-buy" name="search" className="d-flex align-items-center justify-content-center font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white  lg:rounded-t-none lg w-full" value="Search" style={{height:"60px"}}>Submit</div>
                                </div>
                                </div>
                        </div>
                    </form>
                </div>
            </div>

                                


            
            <div className="container relative mt-24">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Explore Job Openings</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Discover a range of exciting job opportunities that matches your skills and aspirations.</p>
                </div>

                <div className="flex justify-center items-center text-center mt-8">
                    <ul className="list-none space-x-3 space-y-4">
                        <li className="inline-block">
                            <Link to="" className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500">
                                {/* <Icons className="me-2 text-[18px]"/> */}
                                <span className="text-[18px] font-medium">Developer</span>
                            </Link>
                        </li>

                        <li className="inline-block">
                            <Link to="" className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500">
                                {/* <Icons className="me-2 text-[18px]"/> */}
                                <span className="text-[18px] font-medium">Designer</span>
                            </Link>
                        </li>

                        <li className="inline-block">
                            <Link to="" className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500">
                                {/* <Icons className="me-2 text-[18px]"/> */}
                                <span className="text-[18px] font-medium">Sales</span>
                            </Link>
                        </li>

                        <li className="inline-block">
                            <Link to="" className="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500">
                                {/* <Icons className="me-2 text-[18px]"/> */}
                                <span className="text-[18px] font-medium">Digital Marketing</span>
                            </Link>
                        </li>

                          
                    </ul>
                </div>
            </div>

            

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Popular Jobs</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Browse our latest openings and take the next step in your career!</p>
                    </div>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        <div className="rounded-md shadow dark:shadow-gray-800 border-top-blue  " >
                        <div className="flex justify-between items-center mt-3 ms-6">
                                <p className=" flex items-center "><b>Noida </b></p>
                            </div>
                            <div className="p-6 pt-3">
                                <Link to className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-bold">ReactJS Developer</Link>
                                <span className=" mt-2 flex items-center text-slate-400 "> Skills: React JS, Laravel, MySQL, Rest API, JavaScript, jQuery    </span>
                                <div className="flex justify-between items-center mt-4">
                                    <p className=" flex items-center "> <b>Experience</b> : 2-3 years</p>
                                </div>
                            </div>

                            <Link to className="flex items-center ms-6 mb-6  border-gray-100 dark:border-gray-700">
                                {/* <img src={item.image} className="size-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" /> */}
                                <div className="d-flex">
                                    <Link to="/job-details" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Explore More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                </div>
                            </Link>
                        </div>

                        <div className="rounded-md shadow dark:shadow-gray-800 border-top-blue" >
                            <div className="flex justify-between items-center mt-3 ms-6">
                                <p className=" flex items-center "><b>Noida </b></p>
                            </div>
                            
                            <div className="p-6 pt-3">
                                {/* <Link to className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-bold">Video Editing</Link> */}
                                <h3 className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-bold">Android Developer</h3>
                                <span className=" mt-2 flex items-center text-slate-400">Skills: Flutter, Java, Cotlin, XML and Dart </span>
                                <div className="flex justify-between items-center mt-4">
                                    <p className=" flex items-center "> <b>Experience</b> : 2-3 years</p>
                                </div>
                            </div>

                            <Link to className="flex items-center ms-6 mb-6  border-gray-100 dark:border-gray-700">
                                {/* <img src={item.image} className="size-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" /> */}
                                <div className="d-flex ">
                                    <Link to="/android" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Explore More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                </div>
                            </Link>
                        </div>

                        <div className="rounded-md shadow dark:shadow-gray-800 border-top-blue" >
                            <div className="flex justify-between items-center mt-3 ms-6">
                                <p className=" flex items-center "><b>Noida </b></p>
                            </div>
                            <div className="p-6 pt-3">
                                <Link to className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-bold">Web Designer</Link>
                                <span className=" mt-2 flex items-center text-slate-400"> Skills: React JS, HTML, Figma, Photoshop, Illustrator  </span>
                                <div className="flex justify-between items-center mt-4">
                                    <p className=" flex items-center "> <b>Experience</b> : 2 years</p>
                                </div>
                            </div>

                            <Link to className="flex items-center ms-6 mb-6  border-gray-100 dark:border-gray-700">
                                {/* <img src={item.image} className="size-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" /> */}
                                <div className="d-flex">
                                    <Link to="/designer" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Explore More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                </div>
                            </Link>
                        </div>

                        <div className="rounded-md shadow dark:shadow-gray-800 border-top-blue" >
                            <div className="flex justify-between items-center mt-3 ms-6">
                                <p className=" flex items-center "><b>Noida </b></p>
                            </div>
                            <div className="p-6 pt-3">
                                <Link to className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-bold">SEO Executive</Link>
                                <span className=" mt-2 flex items-center text-slate-400"> Excellent SEO skills, including understanding basic strategies and technical SEO </span>
                                <div className="flex justify-between items-center mt-4">
                                    <p className=" flex items-center "> <b>Experience</b> : 1 year</p>
                                </div>
                            </div>

                            <Link to className="flex items-center ms-6 mb-6  border-gray-100 dark:border-gray-700">
                                {/* <img src={item.image} className="size-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" /> */}
                                <div className="d-flex ">
                                    <Link to="/seo" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Explore More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                </div>
                            </Link>
                        </div>

                        <div className="rounded-md shadow dark:shadow-gray-800 border-top-blue" >
                            <div className="flex justify-between items-center mt-3 ms-6">
                                <p className=" flex items-center "><b>Noida </b></p>
                            </div>
                            <div className="p-6 pt-3">
                                <Link to className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-bold">Sales Executive (Greater Noida West)</Link>
                                <span className=" mt-2 flex items-center text-slate-400"> Promoting the company's existing products and introducing new products to the market. </span>
                                <div className="flex justify-between items-center mt-4">
                                    <p className=" flex items-center "> <b>Experience</b> : 1-2 years</p>
                                </div>
                            </div>

                            <Link to className="flex items-center ms-6 mb-6  border-gray-100 dark:border-gray-700">
                                {/* <img src={item.image} className="size-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" /> */}
                                <div className="d-flex ">
                                    <Link to="/sales-marketing" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Explore More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                </div>
                            </Link>
                        </div>

                        <div className="rounded-md shadow dark:shadow-gray-800 border-top-blue" >
                            <div className="flex justify-between items-center mt-3 ms-6">
                                <p className=" flex items-center "><b>Kota </b></p>
                            </div>
                            <div className="p-6 pt-3">
                                <Link to className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-bold">Sales Executive (Kota)</Link>
                                <span className=" mt-2 flex items-center text-slate-400">Promoting the company's existing products and introducing new products to the market.</span>
                                <div className="flex justify-between items-center mt-4">
                                    <p className=" flex items-center "> <b>Experience</b> : 1-2 years</p>
                                </div>
                            </div>

                            <Link to className="flex items-center ms-6 mb-6  border-gray-100 dark:border-gray-700">
                                {/* <img src={item.image} className="size-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" /> */}
                                <div className="d-flex ">
                                    <Link to="/sales-marketing" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Explore More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                </div>
                            </Link>
                        </div> 
                    </div>

                    {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                        <div className="md:col-span-12 text-center">
                            <Link to="/page-job-grid" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">See More Jobs <FaArrowRight className="ms-2 text-[10px]"/></Link>
                        </div>
                    </div> */}
                </div>

                
            </section>
            <JobFooter/>
         <CookieModal/>
        </>
    )
}
