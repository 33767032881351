import React from "react";
import { Link } from "react-router-dom";
import logo_light from '../../assets/images/blurb-light.png';

import {FaRegEnvelope,FaDribbble,FaLinkedin, FaFacebookF, FaInstagram, FaTwitter,FaRegFile,FaBehance, PiShoppingCart, MdKeyboardArrowRight} from '../../assets/icons/icons'

export default function JobFooter(){
    return(
        <footer className="footer py-4 border-t  relative text-gray-200 border-t border-slate-800">
            <div className="container relative text-center">
                <div className="grid grid-cols-1">
                    <div className=" px-0">
                        <div className="grid grid-cols-12 items-center">
                            <div className="md:text-start  col-span-12">
                                <img src={logo_light} alt="" className='footer-logo p-0'/>
                                <p className="mb-0 text-sm">All Rights Reserved © 2024 Blurb Software Private Limited</p>

                                   {/* <ul className="list-none mt-5 space-x-1 space-y-1">
                                 
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaLinkedin className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTwitter className='text-sm'/></Link></li>
                                
                                </ul> */}
                            </div>

                            {/* <div className="text-center">
                                <p className="mb-0">©  Blurb Software Private Limited.</p>
                            </div> */}

                            {/* <div className="col-span-6">
                                <h3 className=" md:text-1xl text-1xl md:leading-normal leading-normal font-semibold text-end relative"> Our Products</h3>
                                <div className="d-flex justify-end  radius-8 width-100">
                                    <img src={Edukrypt} alt="" className='footer-logo p-0 object-contain ms-2 relative top-3 bg-white p-2 pt-0 pb-0'/>
                                    <img src={Edumix} alt="" className='footer-logo p-0 object-contain ms-2 bg-white relative top-3 p-2 pt-0 pb-0'/>
                                    <img src={Eduvsat} alt="" className='footer-logo p-0 object-contain ms-2 relative top-3 bg-white p-2 pt-0 pb-0'/>
                                    <img src={Interactive} alt="" className='footer-logo p-0 object-contain ms-2 relative top-3 bg-white p-2 pt-0 pb-0'/>
                                    <img src={Leadai} alt="" className='footer-logo p-0 object-contain ms-2 relative top-3 bg-white p-2 pt-0 pb-0'/>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>

                <div className="grid grid-cols-1 border-t border-slate-800 mt-3">
                    <div className=" px-0">
                        <div className="grid md:grid-cols-1 items-center mt-4">
                            <ul className="list-none footer-list  text-center mt-6 md:mt-0">
                                <li className="inline"><Link to="#" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out text-sm"> Privacy Policy</Link></li>
                                <li className="inline"><Link to="#" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out text-sm">|</Link></li>
                                <li className="inline mt-[10px]"><Link to="#" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out  text-sm">Terms & Conditions</Link></li>
                                {/* <li className="inline mt-[10px]"><Link to="#" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Contact</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>

        </footer>
    )
}