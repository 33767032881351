import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import avatar from '../../assets/images/avatar.jpg';
import ab2 from '../../assets/images/law/ab2.jpg';
import about2 from '../../assets/images/company/about2.png';
import Navbar from '../../component/Navbar/navbar'
import {FiHexagon } from '../../assets/icons/icons'

// import Footer from '../../component/Footer/footer';

import JobFooter from '../../component/Footer/jobFooter';

import {FaArrowRight} from '../../assets/icons/icons'

export default function IndexLandingFive() {
    let [setOpen] = useState(false)
    return (
        <>
            <Navbar/>

            <section className="relative py-40 lg:h-screen flex justify-center items-center bg-indigo-600/10 overflow-hidden" id="home">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-[30px] mt-10 items-center">
                        <div className="lg:col-span-7">
                            <div className="md:text-start text-center">
                                <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Resources for <span className="text-indigo-600">makers &</span> <br /> <span className="text-indigo-600">Creative</span> to learn & grow</h1>
                                <p className="text-slate-400 text-lg max-w-xl">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS v3.x html page.</p>

                                {/* <div className="subcribe-form mt-6 mb-3">
                                    <form className="relative max-w-lg">
                                        <input type="email" id="subcribe" name="email" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="Your Email Address :" />
                                        <button type="submit" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full">Try it for free <FaArrowRight className="ms-2 text-[10px]"/></button>
                                    </form>
                                </div> */}

                                <span className="text-slate-400 font-medium">Looking for help? <Link className="text-indigo-600">Get in touch with us</Link></span>
                            </div>
                        </div>

                        <div className="lg:col-span-5 pt-24">
                            <div className="relative">
                                <div className="relative">
                                    <img src={avatar} className="lg:w-[400px] w-[280px] rounded-lg shadow dark:shadow-gray-800" alt="" />
                                    <div className="absolute top-0 translate-y-2/4 end-0 text-center">
                                        <Link onClick={() => setOpen(true)} data-type="youtube" data-id="S_CGed6E610" className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="absolute -end-5 -bottom-16">
                                    <img src={ab2} className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-lg shadow dark:shadow-gray-800" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container relative md:mt-24 mt-16 pb-24">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src={about2} className="mx-auto" alt="" />
                                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link  onClick={() => setOpen(true)}  data-type="youtube" data-id="S_CGed6E610"
                                        className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="md:col-span-7">
                            <div className="lg:ms-4">
                                <h4 className="mb-6 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">We are the largest <br /> Business expert </h4>
                                <p className="text-slate-400 max-w-xl">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used in the publishing industry or by web designers to occupy the space which will later be filled with 'real' content. This is required when, for example, the final text is not yet available. Dummy texts have been in use by typesetters since the 16th century.</p>
                                <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-3">Buy Now <i className="mdi mdi-chevron-right align-middle"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}
         
                
            <section className="relative" id="features">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Business strategies and top </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                    <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container relative md:mt-24 mt-16 pb-24">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What we do ?</h3>
                    <p class="text-slate-400 max-w-xl mx-auto">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
                </div>

                <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">

                    <div  class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></FiHexagon>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                                <img src='images/index/flexible.png' className='width15'></img>
                            </div>
                        </div>

                        <div class="mt-6">
                            <Link to="" class="text-lg font-medium group-hover:text-white duration-500">Hign Performance</Link>
                            <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">It is a long established fact that a reader.</p>
                        </div>
                    </div>

                    <div  class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></FiHexagon>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                                <img src='images/index/flexible.png' className='width15'></img>
                            </div>
                        </div>

                        <div class="mt-6">
                            <Link to="" class="text-lg font-medium group-hover:text-white duration-500">Hign Performance</Link>
                            <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">It is a long established fact that a reader.</p>
                        </div>
                    </div>

                    <div  class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></FiHexagon>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                                <img src='images/index/flexible.png' className='width15'></img>
                            </div>
                        </div>

                        <div class="mt-6">
                            <Link to="" class="text-lg font-medium group-hover:text-white duration-500">Hign Performance</Link>
                            <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">It is a long established fact that a reader.</p>
                        </div>
                    </div>
                </div>
            </div>
            </section>


            <section className="relative md:py-24  py-16 bg-[url('../../assets/images/crypto/bg2.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-indigo-600/90"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-medium text-white">Our Benefits</h3>

                        <p className="text-white/70 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>

                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        <div className="border border-dashed border-white/30 rounded-md p-6 text-center"  >
                            {/* <Icons  className="size-9 text-white mx-auto"/> */}

                            <div className="content mt-7">
                                <Link to="#" className="text-lg font-medium text-white">Support</Link>
                                <p className="text-white/70 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-white hover:text-white after:bg-white duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="border border-dashed border-white/30 rounded-md p-6 text-center"  >
                            {/* <Icons  className="size-9 text-white mx-auto"/> */}

                            <div className="content mt-7">
                                <Link to="#" className="text-lg font-medium text-white">Support</Link>
                                <p className="text-white/70 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-white hover:text-white after:bg-white duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="border border-dashed border-white/30 rounded-md p-6 text-center"  >
                            {/* <Icons  className="size-9 text-white mx-auto"/> */}

                            <div className="content mt-7">
                                <Link to="#" className="text-lg font-medium text-white">Support</Link>
                                <p className="text-white/70 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-white hover:text-white after:bg-white duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="border border-dashed border-white/30 rounded-md p-6 text-center"  >
                            {/* <Icons  className="size-9 text-white mx-auto"/> */}

                            <div className="content mt-7">
                                <Link to="#" className="text-lg font-medium text-white">Support</Link>
                                <p className="text-white/70 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-white hover:text-white after:bg-white duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="border border-dashed border-white/30 rounded-md p-6 text-center"  >
                            {/* <Icons  className="size-9 text-white mx-auto"/> */}

                            <div className="content mt-7">
                                <Link to="#" className="text-lg font-medium text-white">Support</Link>
                                <p className="text-white/70 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-white hover:text-white after:bg-white duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="border border-dashed border-white/30 rounded-md p-6 text-center"  >
                            {/* <Icons  className="size-9 text-white mx-auto"/> */}

                            <div className="content mt-7">
                                <Link to="#" className="text-lg font-medium text-white">Support</Link>
                                <p className="text-white/70 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-white hover:text-white after:bg-white duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <JobFooter/>

        </>
    )
}
