import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/index';
import Navbar from './component/Navbar/navbar';
// import Topbar from './component/Topbar';
import Industries from './pages/index/industries';
import Restaurant from './pages/index/restaurant';
import JobDetails from './pages/index/job-details';
import Android from './pages/index/android';
import JobApply from './pages/index/job-apply';
import Sales from './pages/index/sales-marketing';
import Designer from './pages/index/designer';
import Seo from './pages/index/seo';
import Career from './pages/index/career';
import Product from './pages/index/product';
import ContactOne from './pages/contact/contactOne';
import Switch from './component/Switch';

export default function App() {
    return (
        <BrowserRouter>
        <Switch/>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route exact path="/index" element={<Index />} />
                <Route exact path="/navbar" element={<Navbar />} />
                <Route exact path="/what_we_do" element={<Restaurant />} />
                <Route exact path="/job-details" element={<JobDetails />} />
                <Route exact path="/android" element={<Android />} />
                <Route exact path="/designer" element={<Designer />} />
                <Route exact path="/seo" element={<Seo />} />
                <Route exact path="/job-apply" element={<JobApply />} />
                <Route exact path="/sales-marketing" element={<Sales />} />
                <Route exact path="/career" element={<Career />} />
                <Route exact path="/product" element={<Product />} />
                <Route exact path="/industries" element={<Industries />} />
                <Route exact path="/contact-one" element={<ContactOne />} />
            </Routes>
        
        </BrowserRouter>
    )
}
