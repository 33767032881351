import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import JobFooter from '../../component/Footer/jobFooter';

import image from '../../assets/images/smartwatch/1.jpg';
import image1 from '../../assets/images/smartwatch/2.jpg';
import CookieModal from '../../component/cookieModal';
import "../../../node_modules/react-modal-video/css/modal-video.css";

import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'

export default function IndexLandingThree() {
    let [isOpen, setOpen] = useState(false)
   
    return (
        <>
            <Navbar navClass="nav-light" />
            <section className="relative table w-full py-24">
                <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-12 gap-[30px]">
                    <div className="md:col-span-7">
                    <div className="me-6">
                        <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">Transforming Ideas into Innovative <span className="text-indigo-600">Solutions</span></h4>
                        <p className="text-slate-400 text-lg max-w-xl">From custom applications to robust cloud solutions, we provide the tools you need to thrive in a digital world.</p>

                        <div className="mt-6">
                        <Link to="" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                        {/* <Link to="/documentation" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"><BiBookAlt className="me-2"/> Documentation</Link> */}
                        </div>
                    </div>
                    </div>

                    <div className="md:col-span-5">
                    <img src='images/index/banner-product.png' alt="" className='radius-8' />
                    </div>
                </div>
                </div>
            </section>

            <section className="relative py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                       
                        <div  className="flex">
                            {/* <div className="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                                
                            </div> */}

                            <div className="content ms-6">
                                <Link to="#" className="text-lg font-medium hover:text-indigo-600">Tailored Solutions</Link>
                                <p className="text-slate-400 mt-3">Our products are designed to adapt to your specific needs and requirements.</p>
                            </div>
                        </div>

                        <div  className="flex">
                            {/* <div className="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                                
                            </div> */}

                            <div className="content ms-6">
                                <Link to="#" className="text-lg font-medium hover:text-indigo-600">Innovative Design</Link>
                                <p className="text-slate-400 mt-3">We leverage the latest technologies to build solutions that are forward-thinking.</p>
                            </div>
                        </div>

                        <div  className="flex">
                            {/* <div className="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                                
                            </div> */}

                            <div className="content ms-6">
                                <Link to="#" className="text-lg font-medium hover:text-indigo-600">Continuous Improvement</Link>
                                <p className="text-slate-400 mt-3">We’re always evolving our products to ensure they stay in a fast-paced market.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Products  </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Explore our innovative products to elevate your business and drive growth!</p>
                    </div>

                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                     
                                <div  className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out">
                                    {/* <div className="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    </div> */}
        
                                    <div className="content mt-7">
                                        <Link to='https://edukrypt.com/' className="md:text-2xl text-2xl md:leading-normal leading-normal font-semibold hover:text-indigo-600">Edukrypt</Link>
                                        <p className="font-medium text-md text-slate-400 mt-3">LMS With Video Encryption & DRM To Protect Your Videos From Piracy</p>
                                        <div className="mt-5">
                                            <Link to='https://edukrypt.com/' className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Explore </Link>
                                        </div>
                                    </div>
                                </div>

                                <div  className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out">
                                    {/* <div className="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    </div> */}
        
                                    <div className="content mt-7">
                                        <Link to=''className="md:text-2xl text-2xl md:leading-normal leading-normal font-semibold hover:text-indigo-600">EduMIXPro</Link>
                                        <p className=" font-medium text-md text-slate-400 mt-3">Video Production Software For Live Streaming & Video Recording</p>
        
                                        <div className="mt-5">
                                            <Link to=''  className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Explore </Link>
                                        </div>
                                    </div>
                                </div>

                                <div  className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out">
                                    {/* <div className="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    </div> */}
        
                                    <div className="content mt-7">
                                        <Link to='https://eduvsatpro.com/' className="md:text-2xl text-2xl md:leading-normal leading-normal font-semibold hover:text-indigo-600">EduVSAT Pro</Link>
                                        <p className=" font-medium text-md text-slate-400 mt-3">Live Interactive Broadcasting Software Over VSAT Network</p>
        
                                        <div className="mt-5">
                                            <Link to='https://eduvsatpro.com/' className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Explore </Link>
                                        </div>
                                    </div>
                                </div>

                                <div  className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out">
                                    {/* <div className="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    </div> */}
        
                                    <div className="content mt-7">
                                        <Link to='https://leadai.co.in/' className="md:text-2xl text-2xl md:leading-normal leading-normal font-semibold hover:text-indigo-600">leadAI</Link>
                                        <p className=" font-medium text-md text-slate-400 mt-3">Lead Software With Automation of the Entire Lifecycle With Inbuilt AI</p>
        
                                        <div className="mt-5">
                                            <Link to='https://leadai.co.in/' className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Explore </Link>
                                        </div>
                                    </div>
                                </div>

                                <div  className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out">
                                    {/* <div className="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    </div> */}
        
                                    <div className="content mt-7">
                                        <Link to='https://theinteractive.in/' className="md:text-2xl text-2xl md:leading-normal leading-normal font-semibold hover:text-indigo-600">The Interactive</Link>
                                        <p className=" font-medium text-md text-slate-400 mt-3">Interactive School Management/ERP Software With All Modules</p>
                                        <div className="mt-5">
                                            <Link to='https://theinteractive.in/' className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Explore </Link>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
            </section>


            <div className="container relative mb-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="md:col-span-5">
                            <div class="grid grid-cols-12 gap-4 items-center">
                                <div class="col-span-7">
                                    <div class="grid grid-cols-1 gap-4">
                                        <img src="images/index/perfect-solution.jpg" class="shadow rounded-lg" alt=""/>
                                    </div>
                                </div>
                                    <div class="col-span-5">
                                        <div class="grid grid-cols-1 gap-4">
                                            <img src="images/index/perfect-solution3.jpg"  class="shadow rounded-lg" alt=""/>
                                            <div class="size-28 bg-indigo-600/10 rounded-lg"></div>
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <div className="md:col-span-7">
                            <div className="lg:ms-4">
                                <h5 className="font-medium text-lg text-indigo-600">Perfect Solution </h5>
                                <h4 className="mb-6 md:text-4xl text-3xl lg:leading-normal leading-normal font-medium">Ready to Take the Next Step?</h4>
                                <p className="text-slate-400 max-w-xl">Explore our products further and see how they can revolutionize your business. We are here to help you find the right solution.</p>
                                    <div className="mt-8">
                                            <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Contact us <i className="mdi mdi-arrow-right align-middle"></i></Link>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            <JobFooter/>

            <CookieModal />
        </>
    )
}
