import React from 'react'
import { Link, useParams } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'

import JobFooter from '../../component/Footer/jobFooter';
import MobileApp from '../../component/mobileApp';

import { jobGrid } from '../../data/data';
import * as Icon from 'react-feather';
import {MdKeyboardArrowRight, RiMapPinLine} from '../../assets/icons/icons'

import {jobDuties,jobRequirements, jobNice,jobBenefits} from "../../data/dataFive"

export default function PageJobDetail() {
    const params = useParams();
    const id = params.id
    const data = jobGrid.find((job) => job.id === parseInt (id));

    return (
        <>
            <Navbar navClass="nav-light" />
            <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/job/job.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-12">
                        <h3 className="mb-4 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">{data?.title ? data?.title : 'Sales Executive'}</h3>

                        <ul className="list-none">
                            <li className="inline-flex items-center text-slate-400 me-3"><RiMapPinLine className="text-white h6 me-2" width={18}/><span className='me-1'>{data?.city ? data?.city : 'Noida'},</span>{data?.place ? data?.place : 'City'} - <span className="text-white  px-1">{data?.duration ? data?.duration : "Full Time"}</span></li>
                        </ul>
                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Blurb</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="index-job">Job</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Job Detail</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 grid-cols-1" id="reserve-form">
                        <div className="lg:col-start-2 lg:col-span-10">
                            {/* <h5 className="mb-4 font-medium text-xl">Job  Description:</h5>
                            <p className="text-slate-400 mb-4">Edukrypt Corporation is the Next Gen Software Company that works in latest technologies to Automate Businesses. Edukrypt Corporation is Headquartered in Noida, to help global organizations. Company’s main focus is on the new and emerging technologies, such as AI, IOT, Big Data, Data Mining, etc. Our service portfolio includes strategic IT Consulting, CX Design, Development and full lifecycle management of custom products and applications, and system integration. The company’s strategy is heavily geared towards development of its own platforms, frameworks and reusable components that are utilized for building custom solutions. Our client list included top Education & Corporate customers. For more information please visit our website <a>https://www.edukrypt.com/</a> 
                            </p> */}

                            <ul className="list-none mb-0">        
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={36} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight> We are looking for an organized sales and marketing executive to assist in the advertising and selling of our company's products and to create competitive advantages for our company in the market industry.                                </li>
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Reports to Team Leader                                </li>
                            </ul>

                            {/* <p className="text-slate-400 mb-4">Our approach is to develop and promote widely accessible products that support economic freedom. For example, our digital wallet - which has 16 million downloads - provides people with an easy-to-use, non-custodial method for buying, selling, storing, sending, receiving, and trading cryptocurrencies.</p> */}

                            <h5 className="mb-4 mt-6 font-medium text-xl">Job Responsibilities:</h5>

                            <ul className="list-none mb-0">        
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Promoting the company's existing brands and introducing new products to the market. </li>
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={26} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Researching and developing marketing opportunities and plans, understanding consumer requirements, identifying market trends, and suggesting system improvements to achieve the company's marketing goals. </li>
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Gathering, investigating, and summarizing market data and trends to draft reports.              </li>
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Implementing new sales plans and advertising.                                </li>
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Maintaining relationships with important clients by making regular visits, understanding their needs, and anticipating new marketing opportunities.                                </li>
                            </ul>

                            <h5 className="mb-4 mt-6 font-medium text-xl">Requirements:   </h5>

                            <ul className="list-none mb-0">
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>1-2 years experience in marketing or sales.</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Candidate should have own Bike & laptop. </li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Understanding and knowledge of sales and marketing.</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Strong analytical, organizational, and creative thinking skills.                                </li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Excellent communication, interpersonal, and customer service skills.</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>The ability to understand and follow company policies and procedures.</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>The ability to work under pressure.</li>
                                {/* <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Familiarity with cloud message APIs and push notifications</li> */}
                                {/* <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Proficient understanding of code versioning tools, such as Git.</li> */}
                            </ul>


                            <h5 className="mb-4 mt-6 font-medium text-xl">BENEFITS:   </h5>

                            <ul className="list-none mb-0">
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>A competitive compensation package that includes a fixed salary and incentive for early completions. </li>
                     
                            </ul>


                            
                            <div className="mt-6">
                                <Link to="/job-apply" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Apply now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <MobileApp/> */}
            </section >
            <JobFooter/>
        </ >
    )
}
