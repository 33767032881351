import React from 'react'
import { Link, useParams } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'

import JobFooter from '../../component/Footer/jobFooter';
import MobileApp from '../../component/mobileApp';

import { jobGrid } from '../../data/data';
import * as Icon from 'react-feather';
import {MdKeyboardArrowRight, RiMapPinLine} from '../../assets/icons/icons'

import {jobDuties,jobRequirements, jobNice,jobBenefits} from "../../data/dataFive"

export default function PageJobDetail() {
    const params = useParams();
    const id = params.id
    const data = jobGrid.find((job) => job.id === parseInt (id));

    return (
        <>
            <Navbar navClass="nav-light" />
            <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/job/job.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-12">
                        <h3 className="mb-4 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">{data?.title ? data?.title : 'ReactJS Developer'}</h3>

                        <ul className="list-none">
                            <li className="inline-flex items-center text-slate-400 me-3"><RiMapPinLine className="text-white h6 me-2" width={18}/><span className='me-1'>{data?.city ? data?.city : 'Noida'},</span>{data?.place ? data?.place : 'City'} - <span className="text-white  px-1">{data?.duration ? data?.duration : "Full Time"}</span></li>
                        </ul>
                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Blurb</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="index-job">Job</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Job Detail</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 grid-cols-1" id="reserve-form">
                        <div className="lg:col-start-2 lg:col-span-10">
                            {/* <h5 className="mb-4 font-medium text-xl">Description:</h5>
                            <p className="text-slate-400 mb-4"> Edukrypt Corporation is the Next Gen Software Company that works in latest technologies to Automate Businesses. Edukrypt Corporation is Headquartered in Noida, to help global organizations. Company’s main focus is on the new and emerging technologies, such as AI, IOT, Big Data, Data Mining, etc. Our service portfolio includes strategic IT Consulting, CX Design, Development and full lifecycle management of custom products and applications, and system integration. The company’s strategy is heavily geared towards development of its own platforms, frameworks and reusable components that are utilized for building custom solutions. Our client list included top Education & Corporate customers. For more information please visit our website <a>https://www.edukrypt.com/</a> </p> */}

                            {/* <p className="text-slate-400 mb-4">Our approach is to develop and promote widely accessible products that support economic freedom. For example, our digital wallet - which has 16 million downloads - provides people with an easy-to-use, non-custodial method for buying, selling, storing, sending, receiving, and trading cryptocurrencies.</p> */}

                            <h5 className="mb-4 mt-6 font-medium text-xl">Duties:</h5>

                            <ul className="list-none mb-0">        
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={36} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight> We are looking to hire a talented PHP developer to manage our back-end services and ensure a seamless interchange of data between the server and our users. As a PHP developer, you will be responsible for developing and coding all server-side logic. You will also be required to maintain the central database and respond to requests from front-end developers.</li>
                                <li className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Reports to Team Leader                                </li>
                            </ul>

                            <h5 className="mb-4 mt-6 font-medium text-xl">Job Responsibilities:   </h5>

                            <ul className="list-none mb-0">
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Conducting analysis of website and application requirements.</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Produce detailed specifications</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Troubleshoot, test and maintain the core product software and databases to ensure strong optimization and functionality  </li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Contribute in all phases of the development lifecycle </li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Develop and deploy new features to facilitate related procedures and tools if necessary</li>
                            </ul>

                            <h5 className="mb-4 mt-6 font-medium text-xl">REQUIREMENTS</h5>

                            <ul className="list-none mb-0">
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight> Proven 2 years of software development experience in PHP (Preferred 1 year of framework experience)</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight> Writing back-end code and building efficient PHP modules.</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight> Demonstrable knowledge of web technologies including HTML5, CSS3, PHP Framework (Laravel, React JS), MySQL, Rest API, JavaScript, jQuery, AJAX, etc.</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight> Good knowledge of relational databases, version control tools and of developing web services</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Experience in common third-party APIs (Google, Facebook, etc.)</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Passion for best design and coding practices and a desire to develop new bold ideas</li>
                                <li  className="text-slate-400 flex mt-2"><Icon.ArrowRight width={16} className="text-indigo-600 h5 mb-0 me-2"></Icon.ArrowRight>Bachelor degree in Computer Science, Engineering or a related subject </li>
                            </ul>

                            <h5 className="mb-4 mt-6 font-medium text-xl">Benefits</h5>
                            <p className="text-slate-400 mb-4">A competitive compensation package that includes a fixed salary and incentive for early completions.</p>

                            {/* <p className="text-slate-400 mb-4">We are serious about what we do, but more importantly, we have a lot of fun doing it. Our work culture is modern, meaning we strive for work experiences based on transparency, productivity, trust, and passion. For all employees, benefits include:</p> */}

                            <div className="mt-6">
                                <Link to="/job-apply" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Apply now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <MobileApp/> */}
            </section >
            <JobFooter/>
        </ >
    )
}
