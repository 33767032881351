import NftImage1 from '../assets/images/nft/items/1.jpg';
import NftImage2 from '../assets/images/nft/items/2.jpg';
import NftImage3 from '../assets/images/nft/items/3.jpg';
import NftImage4 from '../assets/images/nft/items/4.jpg';
import NftImage5 from '../assets/images/nft/items/5.jpg';
import NftImage6 from '../assets/images/nft/items/6.jpg';
import NftImage7 from '../assets/images/nft/items/7.jpg';
import NftImage8 from '../assets/images/nft/items/8.jpg';
import NftImage9 from '../assets/images/nft/items/9.jpg';
import NftImage10 from '../assets/images/nft/items/10.jpg';
import NftImage11 from '../assets/images/nft/items/11.jpg';
import NftImage12 from '../assets/images/nft/items/12.jpg';

import client1 from '../assets/images/client/01.jpg';
import client2 from '../assets/images/client/02.jpg';
import client3 from '../assets/images/client/03.jpg';
import client4 from '../assets/images/client/04.jpg';
import client5 from '../assets/images/client/05.jpg';
import client6 from '../assets/images/client/06.jpg';
import client7 from '../assets/images/client/07.jpg';
import client8 from '../assets/images/client/08.jpg';

import facebook_logo_2019 from '../assets/images/client/facebook-logo-2019.png';
import google_logo from '../assets/images/client/google-logo.png';
import linkedin from '../assets/images/client/linkedin.png';
import skype from '../assets/images/client/skype.png';
import spotify from '../assets/images/client/spotify.png';
import telegram from '../assets/images/client/telegram.png';
import circle_logo from '../assets/images/client/circle-logo.png';
import lenovo_logo from '../assets/images/client/lenovo-logo.png';
import shree_logo from '../assets/images/client/shree-logo.png';
import snapchat from '../assets/images/client/snapchat.png';
import android from '../assets/images/client/android.png';

import course1 from '../assets/images/course/c1.jpg';
import course2 from '../assets/images/course/c2.jpg';
import course3 from '../assets/images/course/c3.jpg';
import course4 from '../assets/images/course/c4.jpg';
import course5 from '../assets/images/course/c5.jpg';
import course6 from '../assets/images/course/c6.jpg';
import course7 from '../assets/images/course/c7.jpg';
import course8 from '../assets/images/course/c8.jpg';
import course9 from '../assets/images/course/c9.jpg';
import course10 from '../assets/images/course/c10.jpg';

import foodBlog1 from '../assets/images/food/blog/1.jpg';
import foodBlog2 from '../assets/images/food/blog/5.jpg';
import foodBlog3 from '../assets/images/food/blog/f1.jpg';
import foodBlog4 from '../assets/images/food/blog/m1.jpg';
import foodBlog5 from '../assets/images/food/blog/2.jpg';
import foodBlog6 from '../assets/images/food/blog/6.jpg';
import foodBlog7 from '../assets/images/food/blog/m2.jpg';
import foodBlog8 from '../assets/images/food/blog/f2.jpg';
import foodBlog9 from '../assets/images/food/blog/2.jpg';
import foodBlog10 from '../assets/images/food/blog/m3.jpg';
import foodBlog11 from '../assets/images/food/blog/7.jpg';
import foodBlog12 from '../assets/images/food/blog/f3.jpg';
import foodBlog13 from '../assets/images/food/blog/f4.jpg';

import blog1 from '../assets/images/blog/01.jpg';
import blog2 from '../assets/images/blog/09.jpg';
import blog3 from '../assets/images/blog/10.jpg';
import blog4 from '../assets/images/blog/11.jpg';
import blog5 from '../assets/images/blog/12.jpg';
import blog6 from '../assets/images/food/blog/1.jpg';
import blog7 from '../assets/images/blog/13.jpg';
import blog8 from '../assets/images/blog/14.jpg';
import blog9 from '../assets/images/food/blog/2.jpg';
import blog10 from '../assets/images/blog/02.jpg';
import blog11 from '../assets/images/blog/03.jpg';
import blog12 from '../assets/images/blog/04.jpg';
import blog13 from '../assets/images/blog/05.jpg';
import blog14 from '../assets/images/blog/06.jpg';
import blog15 from '../assets/images/blog/07.jpg';
import blog16 from '../assets/images/blog/08.jpg';

import Property1 from '../assets/images/real/property/1.jpg'
import Property2 from '../assets/images/real/property/2.jpg'
import Property3 from '../assets/images/real/property/3.jpg'
import Property4 from '../assets/images/real/property/4.jpg'
import Property5 from '../assets/images/real/property/5.jpg'
import Property6 from '../assets/images/real/property/6.jpg'
import Property7 from '../assets/images/real/property/7.jpg'
import Property8 from '../assets/images/real/property/8.jpg'
import Property9 from '../assets/images/real/property/9.jpg'
import Property10 from '../assets/images/real/property/10.jpg'
import Property11 from '../assets/images/real/property/11.jpg'
import Property12 from '../assets/images/real/property/12.jpg'

import { FiPhone, FaRegEnvelope, RiMapPinLine} from "../assets/icons/icons"

export const nftData = [
    {
        id:1,
        image:NftImage1,
        title:"Deep Sea Phantasy",
        amount:"20.5 ETH",
        like : '22',
        date: 'December 29, 2023 6:0:0',
        dataGroup: '["branding"]',
        category: 'branding',
        auction:'December 29, 2023 6:0:0'
    },
    {
        id:2,
        image:NftImage2,
        title:"CyberPrimal 042 LAN",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["development"]',
        category: 'development',
        auction:'January 29, 2024 6:0:0'
    },
    {
        id:3,
        image:NftImage3,
        title:"Crypto Egg Stamp #5",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["branding"]',
        category: 'branding',
        auction:'February 29, 2024 6:0:0'
    },
    {
        id:4,
        image:NftImage4,
        title:"Color Abstract Painting",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["branding"]',
        category: 'branding',
        auction:'March 10, 2024 6:0:0'
    },
    {
        id:5,
        image:NftImage5,
        title:"Liquid Forest Princess",
        amount:"20.5 ETH",
        like : '22',
        date: 'January 13, 2024 5:3:1',
        auction:'April 15, 2024 6:0:0'
    },
    {
        id:6,
        image:NftImage6,
        title:"Spider Eyes Modern Art",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["branding"]',
        category: 'branding',
        auction:'March 19, 2024 6:0:0'
    },
    {
        id:7,
        image:NftImage7,
        title:"Synthwave Painting",
        amount:"20.5 ETH",
        like : '22',
        date: 'December 10, 2023 6:0:0',
        dataGroup: '["designing"]',
        category: 'designing',
        auction:'April 4, 2024 6:0:0'
    },
    {
        id:8,
        image:NftImage8,
        title:"Contemporary Abstract",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["development"]',
        category: 'development',
        auction:'May 1, 2024 6:0:0'
    },
    {
        id:9,
        image:NftImage9,
        title:"CyberPrimal 042 LAN",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["development"]',
        category: 'development',
        auction:'March 13, 2024 6:0:0'
    },
    {
        id:10,
        image:NftImage10,
        title:"Crypto Egg Stamp #5",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["designing"]',
        category: 'designing',
        date: 'December 20, 2023 6:0:0',
        auction:'June 19, 2024 6:0:0'
    },
    {
        id:11,
        image:NftImage11,
        title:"Color Abstract Painting",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["photography"]',
        category: 'photography',
        auction:'June 20, 2024 6:0:0'
    },
    {
        id:12,
        image:NftImage12,
        title:"Liquid Forest Princess",
        amount:"20.5 ETH",
        like : '22',
        dataGroup: '["designing"]',
        category: 'designing',
        date: 'December 10, 2023 6:0:0',
        auction:'May 5, 2024 6:0:0'
    },
]
export  const  creatorsData = [
    {id:1,image:client1,name:"Calvin Carlo", title:"@calvin_carlo", background:" bg-gradient-to-r to-orange-600/70 from-indigo-600/70"},
    {id:2, image:client2,name:"Tiffany Betancourt", title:"@cutiegirl", background:" bg-gradient-to-r to-red-600/70 from-indigo-600/70"},
    {id:3, image:client3,name:"Mari Harrington", title:"@norse_queen", background:" bg-gradient-to-r to-fuchsia-600/70 from-green-600/70"},
    {id:4, image:client4,name:"Floyd Glasgow", title:"@bigbull", background:"bg-gradient-to-r to-pink-600/70 from-purple-600/70"},
    {id:5, image:client5, name:"Donna Schultz", title:"@angel", background:"bg-gradient-to-r to-indigo-600/70 from-blue-600/70"},
    {id:6, image:client6,name:"Joshua Morris", title:"@crazyanyone", background:"bg-gradient-to-r to-orange-600/70 from-indigo-600/70"},
    {id:7,  image:client7,name:"Carl Williams", title:"@looserbad", background:" bg-gradient-to-r to-sky-600/70 from-yellow-600/70"},
    {id:8, image:client8,name:"Eugene Green", title:"@kristyhoney", background:"bg-gradient-to-r to-lime-600/70 from-rose-600/70"},
    {id:9, image:client1,name:"Calvin Carlo", title:"@calvin_carlo", background:" bg-gradient-to-r to-orange-600/70 from-indigo-600/70"},
    {id:10, image:client2,name:"Tiffany Betancourt", title:"@cutiegirl", background:"bg-gradient-to-r to-red-600/70 from-indigo-600/70"},
    {id:11, image:client3,name:"Mari Harrington", title:"@norse_queen", background:"bg-gradient-to-r to-fuchsia-600/70 from-green-600/70"},
    {id:12, image:client5,name:"Floyd Glasgow", title:"@bigbull", background:"bg-gradient-to-r to-pink-600/70 from-purple-600/70"},
    {id:13, image:client6,name:"Donna Schultz", title:"@angel", background:"bg-gradient-to-r to-indigo-600/70 from-blue-600/70"},
    {id:14, image:client7,name:"Joshua Morris", title:"@crazyanyone", background:"bg-gradient-to-r to-orange-600/70 from-indigo-600/70"},
    {id:15, image:client8,name:"Carl Williams", title:"@looserbad", background:"bg-gradient-to-r to-sky-600/70 from-yellow-600/70"},
]
export   const jobGrid = [
        {
            id:1,
            title: 'Software Engineering',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$950 - $1100/mo',
            image: facebook_logo_2019,
            company: 'Facebook Ltd.',
            name:'Facebook',
            place: 'Australia',
            city:"Commonwealth"
        },
        {
            id:2,
            title: 'Web Developer',
            date: 'Posted 3 Days ago',
            duration: 'Remote',
            money: '$2500 - $2600/mo',
            image: google_logo,
            company: 'Google Ltd.',
            name:'Google',
            place: 'America',
            city:"Yerevan"
        },
        {
            id:3,
            title: 'UX/UI Designer',
            date: 'Posted 3 Days ago',
            duration: 'Freelance',
            money: '$3500 - $3600/mo',
            image: linkedin,
            company: 'Linkedin Ltd.',
            name:'Linkedin',
            place: 'Canada',
            city:"Ottawa"
        },
        {
            id:4,
            title: 'Human Resource(HR)',
            date: 'Posted 3 Days ago',
            duration: 'Part Time',
            money: '$2000 - $2500/mo',
            image: skype,
            company: 'Skype Ltd.',
            name:'Skype',
            place: 'Indonesia',
            city:"Jakarta"
        },
        {
            id:5,
            title: 'Web Designer',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$1500 - $1600/mo',
            image: spotify,
            company: 'Spotify Ltd.',
            name:'Spotify',
            place: 'Greece',
            city:"	Athens"
        },
        {
            id:6,
            title: 'Graphic Designer',
            date: 'Posted 3 Days ago',
            duration: 'Part time',
            money: '$500 - $600/mo',
            image: telegram,
            company: 'Telegram Ltd.',
            name:'Telegram',
            place: 'Germany',
            city:"	Berlin"
        },
        {
            id:7,
            title: 'Senior Web Developer',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$950 - $1100/mo',
            image: circle_logo,
            company: 'Circle CI Ltd.',
            name:'Circle',
            place: 'Australia',
            city:"	Canberra"
        },
        {
            id:8,
            title: 'Front-End Developer',
            date: 'Posted 3 Days ago',
            duration: 'Remote',
            money: '$2500 - $2600/mo',
            image: lenovo_logo,
            company: 'Lenovo Ltd.',
            name:'Lenovo',
            place: 'Austria',
            city:"Vienna"
        },
        {
            id:9,
            title: 'Back-End Developer',
            date: 'Posted 3 Days ago',
            duration: 'Freelance',
            money: '$3500 - $3600/mo',
            image: shree_logo,
            company: 'Shreethemes Ltd.',
            name:'Shreethemes',
            place: 'Canada',
            city:"	Ottawa"
        },
        {
            id:10,
            title: 'Data Entry',
            date: 'Posted 3 Days ago',
            duration: 'Part Time',
            money: '$2000 - $2500/mo',
            image: snapchat,
            company: 'Snapchat Ltd.',
            name:'Snapchat',
            place: 'France',
            city:"Paris"
        },
        {
            id:11,
            title: 'Android Developer',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$1500 - $1600/mo',
            image: android,
            company: 'Android Ltd.',
            name:'Android',
            place: 'China',
            city:"Beijing"
        },
        {
            id:12,
            title: 'Sketch Designer',
            date: 'Posted 3 Days ago',
            duration: 'Part time',
            money: '$500 - $600/mo',
            image: shree_logo,
            company: 'Shreethemes Ltd.',
            name:'Shreethemes',
            place: 'India',
            city:"New Delhi"
        },
    ]
export const candidates = [
    { 
        id:1,
        image: client1,
        name:"Steven Townsend",
        job:'Web Designer',
        place:'India'
    },
    { 
        id:2,
        image: client2,
        name:"Tiffany Betancourt",
        job:'Web Developer' ,
        place:'China'
    },
    { 
        id:3,
        image: client3,
        name:"Jacqueline Burns",
        job:'Software Engineering',
        place:'France'
     },
    { 
        id:4,
        image: client4,
        name:"Mari Harrington",
        job:'UX/UI Designer',
        place:'Canada'
     },
    { 
        id:5,
        image: client5,
        name:"Floyd Glasgow" ,
        job:'Graphic Designer',
        place:'Australia'
    },
    { 
        id:6,
        image: client6,
        name:"Donna Schultz",
        job:'Front-End Developer' ,
        place:'Germany'
    },
    { 
        id:7,
        image: client7,
        name:"Joshua Morris",
        job:'Senior Web Developer' ,
        place:'Indonesia'
    },
    { 
        id:8,
        image: client8,
        name:"Rosaria Vargas",
        job:'Back-End Developer',
        place:'America'
    }
]   
export   const courseListing = [
    {
        id:1,
        image: course1,
        image2: client1,
        name: 'Calvin Carlo',
        role: 'Professor',
        field: 'Digital Marketing',
        desc: 'Starting SEO as your Home Based Business'
    },
    {
        id:2,
        image: course2,
        image2: client2,
        name: 'Christa Smith',
        role: 'Professor',
        field: 'Engineering',
        desc: 'Java Programming Masterclass for Software'
    },
    {
        id:3,
        image: course3,
        image2: client3,
        name: 'Jani Jangad',
        role: 'Professor',
        field: 'Engineering',
        desc: 'Microsoft Excel - Excel from Beginner to Advanced'
    },
    {
        id:4,
        image: course4,
        image2: client4,
        name: 'John Cartwright',
        role: 'Professor',
        field: 'Web Development',
        desc: 'Vue - The Complete Guide (w/ Router, Composition API)'
    },
    {
        id:5,
        image: course5,
        image2: client5,
        name: 'Sally Short',
        role: 'Professor',
        field: 'Front-end Design',
        desc: 'HTML, CSS, Javascripts, and Basic Knowledge about web design'
    },
    {
        id:6,
        image: course6,
        image2: client6,
        name: 'William Benson',
        role: 'Professor',
        field: 'Back-end Development',
        desc: 'C Programming For Beginners - Master the C Language'
    },
    {
        id:7,
        image: course7,
        image2: client7,
        name: 'Christa Smith',
        role: 'Professor',
        field: 'Engineering',
        desc: 'Java Programming Masterclass for Software'
    },
    {
        id:8,
        image: course8,
        image2: client8,
        name: 'Jani Jangad',
        role: 'Professor',
        field: 'Engineering',
        desc: 'Microsoft Excel - Excel from Beginner to Advanced'
    },
    {
        id:9,
        image: course9,
        image2: client1,
        name: 'John Cartwright',
        role: 'Professor',
        field: 'Web Development',
        desc: 'Vue - The Complete Guide (w/ Router, Composition API)'
    },
    {
        id:10,
        image: course10,
        image2: client2,
        name: 'Sally Short',
        role: 'Professor',
        field: 'Front-end Design',
        desc: 'HTML, CSS, Javascripts, and Basic Knowledge about web design'
    }
] 
export const foodBlogData = [
    {
        id:1,
        image:foodBlog1,
        tag:"Salad",
        title:"Giant Multi-Stuffed Soft Pretzel",
        date:"13th September 2023",
    },
    {
        id:2,
        image:foodBlog2,
        tag:"Breakfast",
        title:"Romantic Breakfast for Two",
        date:"29th Novenber 2023",
    },
    {
        id:3,
        image:foodBlog3,
        tag:"Breads",
        title:"Macchiato Pumpkin Soup",
        date:"29th December 2023",
    },
    {
        id:4,
        image:foodBlog4,
        tag:"Chef",
        title:"Braised Pork Belly Adobo By Chef Leah Cohen",
        date:"13th March 2023",
    },
    {
        id:5,
        image:foodBlog5,
        tag:"Breads",
        title:"Cinnamon Buns for Two",
        date:"6th May 2023",
    },
    {
        id:6,
        image:foodBlog6,
        tag:"Cooker",
        title:"Slow Cooker Coconut Curry",
        date:"19th June 2023",
    },
    {
        id:7,
        image:foodBlog7,
        tag:"Birthday",
        title:"Perfect for a Birthday Party",
        date:"20th June 2023",
    },
    {
        id:8,
        image:foodBlog8,
        tag:"Eat",
        title:"5 Recipes Almost Too Cute To Eat",
        date:"31st August 2023",
    },
    {
        id:9,
        image:foodBlog9,
        tag:"Vegetarian",
        title:"Vegetarian Breakfast for You",
        date:"1st September 2023",
    },
    {
        id:10,
        image:foodBlog10,
        tag:"Breads",
        title:"Meal Prep Breakfast For The Week",
        date:"14th July 2022",
    },
    {
        id:11,
        image:foodBlog11,
        tag:"Cheese",
        title:"Lactose Free Homemade Cheese",
        date:"14th July 2022",
    },
    {
        id:12,
        image:foodBlog12,
        tag:"Salad",
        title:"Mint-Pomegranate Salad",
        date:"14th July 2022",
    },
    {
        id:13,
        image:foodBlog13,
        tag:"Apple",
        title:"Tiny Apple Pies with Cinnamon",
        date:"14th July 2022",
    },
]
export const blogData = [
    {
        id:1,
        image:blog1,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Advisory',
        date:'13th September 2023',
        client:client1,
        name:"Steven Townsend",
    },
    {
        id:2,
        image:blog2,
        title:'How apps is changing the IT world',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Travelling',
        date:'29th November 2023',
        client:client2,
        name:"Tiffany Betancourt",
    },
    {
        id:3,
        image:blog3,
        title:'Smartest Applications for Business',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Advisory',
        date:'29th December 2023',
        client:client3,
        name:"Jacqueline Burns",
    }, 
     {
        id:4,
        image:blog4,
        title:'Stop Worrying About Deadlines! We Got You Covered',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Tech',
        date:'13th March 2023',
        client:client4,
        name:"Mari Harrington",
    },
    {
        id:5,
        image:blog5,
        title:'Everything About Financial Modeling',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Lifestyle',
        date:'6th May 2023',
        client:client5,
        name:"Floyd Glasgow" ,
    },
    {
        id:6,
        image:blog6,
        title:'Change Your Strategy: Find a Business Consultant',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'19th June 2023',
        client:client6,
        name:"Donna Schultz",
    },
    {
        id:7,
        image:blog7,
        title:'How apps is changing the IT world',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Lifestyle',
        date:'20th June 2023',
        client:client7,
        name:"Joshua Morris",
    },
    {
        id:8,
        image:blog8,
        title:'On the other hand we provide',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Hotel',
        date:'31st August 2023',
        client:client8,
        name:"Rosaria Vargas",
    },
    {
        id:9,
        image:blog9,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'1st September 2023',
        client:client1,
        name:"Rosaria Vargas",
    },
    {
        id:10,
        image:blog1,
        title:'How apps is changing the IT world',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'13th September 2023',
        client:client1,
        name:"Steven Townsend",
    },
    {
        id:11,
        image:blog10,
        title:'Smartest Applications for Business',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'29th November 2023',
        client:client2,
        name:"Tiffany Betancourt",
    },
    {
        id:12,
        image:blog11,
        title:'Mobile Marketing, Its Synthes and 2021 Offer Prognosis',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'29th December 2023',
        client:client3,
        name:"Jacqueline Burns",
    },
    {
        id:13,
        image:blog12,
        title:'Stop Worrying About Deadlines! We Got You Covered',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'13th March 2023',
        client:client4,
        name:"Mari Harrington",
    },
    {
        id:14,
        image:blog13,
        title:'Change Your Strategy: Find a Business Consultant',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'6th May 2023',
        client:client5,
        name:"Floyd Glasgow",
    },
    {
        id:15,
        image:blog14,
        title:'Everything About Financial Modeling',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'19th June 2023',
        client:client6,
        name:"Donna Schultz",
    },
    {
        id:16,
        image:blog15,
        title:'On the other hand we provide denounce',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'20th June 2023',
        client:client7,
        name:"Joshua Morris",
    },
    {
        id:17,
        image:blog16,
        title:'Smartest Applications for Business',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        category:'Food',
        date:'31st August 2023',
        client:client8,
        name:"Rosaria Vargas",
    },
]
export  const RecentPostData = [
    {
        Image: blog14,
        Class: 'flex items-center mt-8',
        Title: 'Consultant Business',
        Date: '13th March 2023'
    },
    {
        Image: blog15,
        Class: 'flex items-center mt-4',
        Title: 'Grow Your Business',
        Date: '5th May 2023'
    },
    {
        Image: blog16,
        Class: 'flex items-center mt-8',
        Title: 'Look On The Glorious Balance',
        Date: '19th June 2023'
    }
];
export const propertyListing = [
    {
        id:1,
        image: Property1,
        address: '10765 Hillshire Ave, Baton Rouge, LA 70810, USA',
        sqrt: '8000sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:2,
        image: Property2,
        address: '59345 STONEWALL DR, Plaquemine, LA 70764, USA',
        sqrt: '8200sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:3,
        image: Property3,
        address: '3723 SANDBAR DR, Addis, LA 70710, USA',
        sqrt: '7500sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:4,
        image: Property4,
        address: 'Lot 21 ROYAL OAK DR, Prairieville, LA 70769, USA',
        sqrt: '6800sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:5,
        image: Property5,
        address: '710 BOYD DR, Unit #1102, Baton Rouge, LA 70808, USA',
        sqrt: '8500sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:6,
        image: Property6,
        address: '5133 MCLAIN WAY, Baton Rouge, LA 70809, USA',
        sqrt: '9000sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:7,
        image: Property7,
        address: '2141 Fiero Street, Baton Rouge, LA 70808',
        sqrt: '8000sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:8,
        image: Property8,
        address: '9714 Inniswold Estates Ave, Baton Rouge, LA 70809',
        sqrt: '7000sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:9,
        image: Property9,
        address: '1433 Beckenham Dr, Baton Rouge, LA 70808, USA',
        sqrt: '8500sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:10,
        image: Property10,
        address: '1574 Sharlo Ave, Baton Rouge, LA 70820, USA',
        sqrt: '8000sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:11,
        image: Property11,
        address: '2528 BOCAGE LAKE DR, Baton Rouge, LA 70809, USA',
        sqrt: '7000sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
    {
        id:12,
        image: Property12,
        address: '1533 NICHOLSON DR, Baton Rouge, LA 70802, USA',
        sqrt: '9000sqf',
        beds: '4 Beds',
        bath: '4 Baths',
    },
]

export const footerLinks = [
    {
        liClass: 'ms-0',
        route: '/page-terms',
        title: 'Terms of Services',

    },
    {
        liClass: 'mt-[10px] ms-0',
        route: '/page-privacy',
        title: 'Privacy Policy',

    },
    {
        route: '/documentation',
        title: 'Documentation',
        liClass: 'mt-[10px] ms-0',
    },
    {
        route: '/changelog',
        title: 'Changelog',
        liClass: 'mt-[10px] ms-0',
    },
    {
        route: '#!',
        title: 'Widget',
        liClass: 'mt-[10px] ms-0',
    }
];
export const footerCompany = [
    {
        liClass: 'ms-0',
        route: '/page-aboutus',
        title: 'About us',
    },
    {
        liClass: 'mt-[10px] ms-0',
        route: '/page-services',
        title: 'Services',

    },
    {
        route: '/page-team',
        title: 'Team',
        liClass: 'mt-[10px] ms-0',

    },
    {
        route: '/page-pricing',
        title: 'Pricing',
        liClass: 'mt-[10px] ms-0',

    },
    {
        route: '/portfolio-creative-four',
        title: 'Project',
        liClass: 'mt-[10px] ms-0',

    },
    {
        route: '/blog',
        title: 'Blog',
        liClass: 'mt-[10px] ms-0',

    },
    {
        route: '/auth-login',
        title: 'Login',
        liClass: 'mt-[10px] ms-0',

    }
]
export const TagscloudItem = [
    {
        item: 'Business'
    },
    {
        item: 'Finance'
    },
    {
        item: 'Marketing'
    },
    {
        item: 'Fashion'
    },
    {
        item: 'Bride'
    },
    {
        item: 'Lifestyle'
    },
    {
        item: 'Travel'
    },
    {
        item: 'Beauty'
    },
    {
        item: 'Video'
    },
    {
        item: 'Audio'
    }
];
export const team = [
    {
        image: client4,
        name: 'Jack John',
        role: 'Designer'
    },
    {
        image: client5,
        name: 'Krista John',
        role: 'Designer'
    },
    {
        image: client6,
        name: 'Roger Jackson',
        role: 'Designer'
    },
    {
        image: client7,
        name: 'Johnny English',
        role: 'Designer'
    },
]
export const teamData =[
    {
        image:client1,
        name:"Ronny Jofra",
        title:"C.E.O.",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-indigo-600/10 dark:bg-indigo-600/30 ",
    },
    {
        image:client2,
        name:"Aliana Rosy",
        title:"HR",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-emerald-600/10 dark:bg-emerald-600/30 ",
    },
    {
        image:client3,
        name:"Sofia Razaq",
        title:"C.O.O.",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-red-600/10 dark:bg-red-600/30  ",
    },
    {
        image:client4,
        name:"Micheal Carlo",
        title:"Director",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-sky-600/10 dark:bg-sky-600/30  ",
    },
    {
        image:client5,
        name:"Ronny Jofra",
        title:"C.E.O.",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-indigo-600/10 dark:bg-indigo-600/30  ",
    },
    {
        image:client6,
        name:"Aliana Rosy",
        title:"HR",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-emerald-600/10 dark:bg-emerald-600/30 ",
    },
    {
        image:client7,
        name:"Sofia Razaq",
        title:"C.O.O.",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-red-600/10 dark:bg-red-600/30 ",
    },
    {
        image:client8,
        name:"Micheal Carlo",
        title:"Director",
        desc:"If the distribution of letters and 'words' is random",
        background:"bg-sky-600/10 dark:bg-sky-600/30",
    },
]

export const feedback =[
   
    {
        description: '" Game Changer for Our Restaurant! Switching to this POS system has streamlined our operations and improved our customer service. "',
        image: client5,
        name: 'Midnight Cafe',
        role: 'Manager'
    },
    {
        description: '" The customer support team is fantastic! They helped us set up and customize the system to fit our needs perfectly. The software is really reliable. "',
        image: client6,
        name: 'Taste Temptations',
        role: 'Owner'
    },

    {
        description: '" Our experience with Flavour POS has been exceptional. The intuitive interface has streamlined our operations, allowing our staff to serve customers more efficiently."',
        image: client5,
        name: 'Grill Cafe House',
        role: 'Owner'
    }
]

export const commentsData = [
    {
        image:client1,
        name:'Taste Temptations',
        date:'13th March 2023 at 01:25 pm',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image:client2,
        name:'Midnight Cafe',
        date:'5th May 2023 at 10:00 am',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image:client3,
        name:'Calvin Carlo',
        date:'19th June 2023 at 09:00 am',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image:client4,
        name:'Calvin Carlo',
        date:'20th June 2023 at 01:25 pm',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
]

export const testimonial = [
    {
        image: client1,
        name: 'Thomas Israel',
        role: 'Student',
        desc: "I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!"
    },
    {
        image: client5,
        name: 'Barbara McIntosh',
        role: 'Student',
        desc: "There are so many things I had to do with my old software that I just don't do at all with Techwind. Suspicious but I can't say I don't love it."
    },
    {
        image: client2,
        name: 'Carl Oliver',
        role: 'Student',
        desc: "The best part about Techwind is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined."
    },
    {
        image: client4,
        name: 'Jill Webb',
        role: 'Student',
        desc: "I'm trying to get a hold of someone in support, I'm in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away."
    },
    {
        image: client3,
        name: 'Barbara McIntosh',
        role: 'Student',
        desc: "I used to have to remit tax to the EU and with Techwind I somehow don't have to do that anymore. Nervous to travel there now though."
    },
    {
        image: client6,
        name: 'Janisha Doll',
        role: 'Student',
        desc: "This is the fourth email I've sent to your support team. I am literally being held in jail for tax fraud. Please answer your damn emails, this is important."
    },
    {
        image: client1,
        name: 'Thomas Israel',
        role: 'Student',
        desc: "I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!"
    },
    {
        image: client5,
        name: 'Barbara McIntosh',
        role: 'Student',
        desc: "There are so many things I had to do with my old software that I just don't do at all with Techwind. Suspicious but I can't say I don't love it."
    },
    {
        image: client2,
        name: 'Carl Oliver',
        role: 'Student',
        desc: "The best part about Techwind is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined."
    },
    {
        image: client4,
        name: 'Jill Webb',
        role: 'Student',
        desc: "I'm trying to get a hold of someone in support, I'm in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away."
    },
    {
        image: client3,
        name: 'Barbara McIntosh',
        role: 'Student',
        desc: "I used to have to remit tax to the EU and with Techwind I somehow don't have to do that anymore. Nervous to travel there now though."
    },
    {
        image: client6,
        name: 'Janisha Doll',
        role: 'Student',
        desc: "This is the fourth email I've sent to your support team. I am literally being held in jail for tax fraud. Please answer your damn emails, this is important."
    },
    {
        image: client1,
        name: 'Thomas Israel',
        role: 'Student',
        desc: "I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!"
    },
    {
        image: client5,
        name: 'Barbara McIntosh',
        role: 'Student',
        desc: "There are so many things I had to do with my old software that I just don't do at all with Techwind. Suspicious but I can't say I don't love it."
    },
    {
        image: client2,
        name: 'Carl Oliver',
        role: 'Student',
        desc: "The best part about Techwind is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined."
    }
]
export const contactData = [
    {
     icon:FiPhone,
     title:"Phone",
     desc:"The phrasal sequence of the is now so that many campaign and benefit",
     contact:"+152 534-468-854"
    },
    {
     icon:FaRegEnvelope,
     title:"Email",
     desc:"The phrasal sequence of the is now so that many campaign and benefit",
     contact:"contact@example.com"
    },
    {
     icon:RiMapPinLine,
     title:"Location",
     desc:"The phrasal sequence of the is now so that many campaign and benefit",
     contact:"View on Google map"
    }
 ]

